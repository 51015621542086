<template>
  <div>
    <v-wait for="fetchingData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <SingleMetric
        @metricGroupChanged="metricGroupChange"
        @metricChanged="metricChange"
      ></SingleMetric>
    </v-wait>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customApi from '../api/metrics.custom'
import SingleMetric from './SingleMetric'

export default {
  name: 'CustomSingleMetric',
  components: {
    SingleMetric
  },
  created() {
    let vm = this
    vm.$wait.start('fetchingData')
    this.fetchMetricsList().then(function() {
      customApi.getSingleMetricData(vm).then(() => {
        vm.$wait.end('fetchingData')
      })
    })
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'metric_args',
      'single_metrics',
      'multi_metrics',
      'chartType'
    ])
  },
  methods: {
    fetchMetricsList() {
      return !this.single_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    metricChange: function(selectedMetric) {
      this.$store.commit('single_metrics', {
        selectedMetric: selectedMetric.metric_id,
        metricsLoading: true
      })
      customApi.getSingleMetricData(this)
    },
    metricGroupChange: function(metricGroupId, isTechnographicReport) {
      this.$store.commit('single_metrics', {
        metricGroupId: metricGroupId,
        individualMetricsList: [],
        selectedMetric: undefined,
        metricsLoading: true
      })
      this.$store.commit('update_metric_args', {
        reportTypeId: metricGroupId,
        technographicReport: isTechnographicReport 
      })
      customApi.getSingleMetricData(this)
      this.updateOtherMetricAttributes(metricGroupId)
    },
    updateOtherMetricAttributes(metricGroupId) {
      this.$store.commit('multi_metrics', { metricGroupId: metricGroupId })
      this.$store.commit('period_metrics', {
        metricGroupId: metricGroupId,
        selectedPeriod: undefined
      })
    }
  }
}
</script>

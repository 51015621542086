<template>
  <div>
    <b-row class="service-table">
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
          responsive
          striped
          hover
          :busy="loading"
          :items="tableData"
          :sort-compare="sortPeerData"
          :sort-by.sync="sortBy"
          :fields="fieldsList"
          :filter="filter"
          @filtered="afterFilter"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template v-slot:cell(bank_name)="data">
            <div class="text-ellipsis" v-b-tooltip :title="data.item.bank_name">
              {{ data.item.bank_name }}
            </div>
          </template>
          <template v-slot:cell(bank_city)="data">
            <div class="text-ellipsis" v-b-tooltip :title="data.item.bank_city">
              {{ data.item.bank_city }}
            </div>
          </template>
          <template v-slot:cell(bank_state)="data">
            <div
              class="text-ellipsis"
              v-b-tooltip
              :title="data.item.bank_state"
            >
              {{ data.item.bank_state }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div class="">{{ data.value || 'NA' }}</div>
          </template>
        </b-table>

        <b-row>
          <b-col md="6" class="my-1">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TechnographicsSingleService',
  props: {
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortBy: 'bank_name',
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: this.tableData.length
    }
  },
  computed: {
    ...mapGetters(['isTechnographicReport']),
    fieldsList() {
      return [
        {
          key: 'bank_name',
          label: 'Financial Institution',
          sortable: true
        },
        {
          key: 'bank_city',
          label: 'City',
          sortable: false
        },
        {
          key: 'bank_state',
          label: 'State',
          sortable: false
        },
        {
          key: 'has_service',
          label: 'Has Service',
          sortable: true
        },
        {
          key: 'vendor',
          label: 'Vendor',
          sortable: true
        },
        {
          key: 'product',
          label: 'Product',
          sortable: true
        }
      ]
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    sortPeerData(record1, record2, field) {
      let columnCompare
      if (field === 'bank_name') {
        let field1 = record1[field] || ''
        let field2 = record2[field] || ''
        columnCompare = field1
          .toString()
          .localeCompare(field2.toString(), undefined, { numeric: true })
      } else {
        let field1 = parseFloat(
          (record1[field] || '0').replace(/[^0-9.-]+/g, '')
        )
        let field2 = parseFloat(
          (record2[field] || '0').replace(/[^0-9.-]+/g, '')
        )

        columnCompare = field1 === field2 ? 0 : field1 < field2 ? -1 : 1
      }
      if (columnCompare === 0) {
        return record1.bank_name < record2.bank_name
          ? -1
          : record1.bank_name > record2.bank_name
          ? 1
          : 0
      } else {
        return columnCompare
      }
    }
  }
}
</script>

<style lang="scss">
.service-table {
  table {
    th:focus {
      outline: none;
    }

    td {
      padding: 0.5rem;
    }
  }
}
</style>

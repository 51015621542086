<template>
  <div>
    <Chart :options="chartOptions" ref="barChart"></Chart>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'InlineChart',
  props: {
    metric: {
      type: Object,
      required: true
    },
    reportMetric: {
      type: Object,
      required: true
    },
    headers: {
      type: Object,
      required: true
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Chart
  },
  data() {
    return {
      columnChart: null
    }
  },
  mounted() {
    if (window.matchMedia) {
      let chart = this.$refs.barChart && this.$refs.barChart.chart
      let mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(() => {
        if (Object.keys(chart).length) {
          chart.reflow()
        }
      })
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['fiTypeMapper', 'showPeers']),
    ...mapGetters(['isAsideOpen', 'selectedInstitution']),
    chartData() {
      let graphData = {
        metric: this.reportMetric.graph_plots
      }

      if (this.showPeers) {
        graphData.peer = this.reportMetric.peer_metrics.peer_graph_plots.map(
          pg => pg || 0
        )
      }

      return graphData
    },
    chartOptions() {
      let fiMetrics = this.chartData.metric.map(function(val) {
        return val == null ? 0 : parseFloat(val)
      })
      let peerMetrics = this.chartData.peer
        ? this.chartData.peer.map(function(val) {
            return val == null ? 0 : parseFloat(val)
          })
        : []

      let min_of_array = Math.min(...fiMetrics, ...peerMetrics)
      let max_of_array = Math.max(...fiMetrics, ...peerMetrics)
      const metricFormat = this.reportMetric.format

      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'spline',
          height: '250px',
          backgroundColor: '#FFFFFF'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: this.reportMetric.name,
          verticalAlign: 'top',
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: this.partnerSite ? '#015b7e' : '#333333'
          }
        },
        subtitle: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        legend: {
          itemMarginTop: -10,
          itemMarginBottom: -10,
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0,
          itemStyle: {
            fontWeight: 'normal'
          }
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            }
          }
        },
        xAxis: {
          categories: [1, 2, 3, 4, 5].map(
            headerIndex => this.headers[`value${headerIndex}`][1]
          ),
          labels: {
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          min: min_of_array - (max_of_array - min_of_array) * 0.1,
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 0,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            enabled: false,
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, metricFormat)
            }
          }
        },
        series: this.chartSeriesData
      }
    },
    chartSeriesData() {
      let seriesData = [
        {
          name: this.fiTypeMapper[this.selectedInstitution.fi_type],
          data: this.chartData.metric.map(val => parseFloat(val)),
          color: this.partnerSite ? '#537CD1' : '#A7CA77'
        }
      ]

      if (this.chartData.peer) {
        seriesData.push({
          name: 'Peers',
          data: this.chartData.peer.map(val => parseFloat(val)),
          color: this.partnerSite ? '#FFDC88' : '#414142'
        })
      }

      return seriesData
    }
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          this.$children[0].chart.reflow()
        }.bind(this),
        200
      )
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped></style>

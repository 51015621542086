<template>
  <div>
    <Highcharts :options="chartOptions" ref="lineChart"></Highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'SingleMetricLineChart',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    format: {
      type: String,
      required: true
    },
    metricGroup: {
      type: Object,
      required: true
    },
    metric: {
      type: Object,
      required: true
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Highcharts: Chart
  },
  data() {
    return {}
  },
  mounted() {
    if (window.matchMedia) {
      let chart = this.$refs.lineChart && this.$refs.lineChart.chart
      let mediaQueryList = window.matchMedia('print')

      mediaQueryList.addListener(() => {
        if (Object.keys(chart).length) {
          chart.reflow()
        }
      })
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['fiTypeMapper']),
    ...mapGetters(['isAsideOpen', 'selectedInstitution']),
    chartOptions() {
      const metricFormat = this.format

      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'spline',
          marginTop: 50
        },
        exporting: {
          scale: 1,
          filename: this.metric.metric_name,
          enabled: !this.renderedForPDF,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              symbol: 'download',
              symbolStroke: this.partnerSite ? '#015B7E' : '#666666'
            }
          },
          chartOptions: {
            title: {
              text: this.metricGroup.group_name
            },
            subtitle: {
              text: this.metric.metric_name
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: this.renderedForPDF ? this.metricGroup.group_name : null,
          style: {
            color: this.partnerSite ? '#015b7e' : '#333333'
          }
        },
        subtitle: {
          text: this.renderedForPDF ? this.metric.metric_name : null
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        tooltip: {
          formatter: function() {
            let formattedValue = Vue.filter('formatLabel')(this.y, metricFormat)
            if (this.series.name === 'Peers') {
              return 'Peer Group : ' + formattedValue
            } else {
              return 'FI : ' + formattedValue
            }
          }
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              }
            }
          }
        },
        xAxis: {
          categories: this.chartData.labels,
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, metricFormat)
            }
          }
        },
        series: this.chartSeriesData
      }
    },
    chartSeriesData() {
      let seriesData = [
        {
          name: this.fiTypeMapper[this.selectedInstitution.fi_type],
          data: this.chartData.datasets[0].data.map(val => parseFloat(val)),
          color: this.partnerSite ? '#537CD1' : '#A7CA77'
        }
      ]

      if (this.chartData.datasets[1]) {
        seriesData.push({
          name: 'Peers',
          data: this.chartData.datasets[1].data.map(val => parseFloat(val)),
          color: this.partnerSite ? '#FFDC88' : '#414142'
        })
      }

      return seriesData
    }
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          let chart = this.$refs.lineChart && this.$refs.lineChart.chart
          if (chart) {
            chart.reflow()
          }
        }.bind(this),
        200
      )
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped></style>

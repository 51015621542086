<template>
  <div>
    <Highcharts :options="chartOptions"></Highcharts>
  </div>
</template>

<script>
// global
import { mapState } from 'vuex'
// ui components
import { Chart } from 'highcharts-vue'

export default {
  name: 'SparkGraph',
  components: {
    Highcharts: Chart
  },
  props: {
    plotData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    chartOptions() {
      return {
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [1, 0, 1, 0],
          height: 20,
          style: {
            overflow: 'visible'
          },
          skipClone: true
        },
        exporting: {
          enabled: false
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [],
          visible: false
        },
        yAxis: {
          labels: {
            enalbled: false
          },
          title: {
            text: null
          },
          startOnTick: true,
          endOnTick: false,
          minRange: 1,
          visible: false
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1.5,
            shadow: false,
            marker: {
              radius: 2
            },
            states: {
              hover: {
                enabled: false
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        },
        series: [
          {
            data: this.plotData,
            color: this.partnerSite ? '#537CD1' : '#7CB6EC'
          }
        ]
      }
    }
  }
}
</script>

<template>
  <div>
    <v-wait for="fetchingData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <MultiMetric
        @updateMetrics="loadMetrics"
        @moveToSingleMetric="changeToSingleMetric"
        @moveToPeriodMetric="changeToPeriodMetric"
      ></MultiMetric>
    </v-wait>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customApi from '../api/metrics.custom'
import MultiMetric from './multiMetric'

export default {
  name: 'CustomMultiMetric',
  components: {
    MultiMetric
  },
  created() {
    let vm = this
    vm.$wait.start('fetchingData')

    this.fetchMetricsList().then(
      function() {
        customApi.getMultiMetricData(vm).then(() => {
          vm.$wait.end('fetchingData')
        })
      }.bind(this)
    )
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'metric_args',
      'multi_metrics',
      'single_metrics',
      'period_metrics',
      'isTechnographicReport'
    ])
  },
  methods: {
    fetchMetricsList() {
      return !this.multi_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    fetchSingleMetricsList() {
      return !this.single_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    fetchPeriodMetricsList() {
      return !this.period_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    loadMetrics(group) {
      this.$store.commit('multi_metrics', {
        metricGroupId: group.id,
        metricsLoading: true
      })
      this.$store.commit('update_metric_args', {
        reportTypeId: group.id,
        technographicReport: group.is_technographics_only || false
      })
      customApi.getMultiMetricData(this)
      this.updateOtherMetricAttributes(group.id)
    },
    changeToSingleMetric(metricData) {
      this.fetchSingleMetricsList().then(
        function() {
          let selectedGroup = this.single_metrics.metricsList.find(
            metric =>
              metric.group_id === parseInt(this.multi_metrics.metricGroupId)
          )
          this.$store.commit('single_metrics', {
            metricGroupId: selectedGroup.group_id,
            selectedMetric: metricData.metric_id
          })
          this.$store.dispatch('setReportView', 'single')
        }.bind(this)
      )
    },
    changeToPeriodMetric(period_id) {
      this.fetchPeriodMetricsList().then(
        function() {
          let selectedGroup = this.period_metrics.metricsList.find(
            metric => metric.id === parseInt(this.multi_metrics.metricGroupId)
          )
          this.$store.commit('period_metrics', {
            metricGroupId: selectedGroup.id,
            selectedPeriod: period_id
          })
          this.$store.dispatch('setReportView', 'period')
        }.bind(this)
      )
    },
    updateOtherMetricAttributes(metricGroupId) {
      this.$store.commit('single_metrics', {
        metricGroupId: metricGroupId,
        selectedMetric: undefined
      })
      this.$store.commit('period_metrics', {
        metricGroupId: metricGroupId,
        selectedPeriod: undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>

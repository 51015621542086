<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th class="pr-0 pl-0 reports-select">
          <multiselect
            track-by="id"
            label="name"
            :allowEmpty="false"
            :showLabels="false"
            :value="selectedReport"
            :options="reports"
            :searchable="false"
            :class="[
              showPeers ? '' : 'service-select',
              'title-select pr-0 pl-0 d-inline-block'
            ]"
            @select="updateMetrics($event)"
          ></multiselect>
        </th>
        <th
          v-if="showPeers"
          class="text-right pr-3 peer-data text-nowrap"
          width="15%"
        >
          <div class="d-inline-block">
            <div class="text-center">
              Peers
            </div>
          </div>
        </th>
        <th v-if="showPeers" class="help-text"></th>
      </tr>
    </thead>
    <tbody v-if="multi_metrics.metricsLoading">
      <tr>
        <td :colspan="showPeers ? 2 : 1">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(service, index) in reportData"
        :key="index"
        :class="service.type"
      >
        <td
          :style="{ paddingLeft: service.indent + 'em' }"
          class="name text-left"
          @click="moveToSingleService(service)"
        >
          {{ service.name }}
        </td>

        <td class="text-right peer-data" v-if="showPeers">
          {{ service.peer }}
        </td>

        <td class="text-muted help-text" v-if="showPeers">
          {{
            service.type === 'vendor'
              ? 'Peers with Vendor Product'
              : service.type === 'service'
              ? 'Peers with Service'
              : ''
          }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// global
import { mapGetters } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
// utils
import MetricFormatter from '../helpers/metric.formatter'

export default {
  name: 'TechnographicReport',
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    reports: {
      type: Array,
      required: true
    }
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(['showPeers', 'multi_metrics']),
    reportData() {
      let metricFormatter = new MetricFormatter()
      return metricFormatter.formattedTechnographicReport
    }
  },
  methods: {
    updateMetrics(selected) {
      this.$emit('updateMetrics', selected)
    },
    moveToSingleService(service) {
      if (service.type === 'service') {
        this.$emit('moveToSingleService', service)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.multi-metrics {
  .table {
    td {
      padding: 0.5rem;
    }

    .peer-data {
      background-color: #f0f0f0;
    }

    .help-text {
      font-size: 0.75rem;
      vertical-align: bottom;
    }

    tr.service {
      td.name {
        cursor: pointer;
        padding-left: 0.75rem;
        font-weight: 600;
      }

      td.peer-data {
        font-weight: 600;
      }
    }

    tr.header {
      td.name {
        cursor: initial;
        font-weight: 600;
      }
    }

    tr.space {
      td.name {
        cursor: initial;
      }
    }

    tr.vendor {
      td {
        padding-top: 1px;
        padding-bottom: 1px;
      }

      td.name {
        padding-left: 12px;
      }
    }

    tr.divider {
      pointer-events: none;
    }
  }

  .table-hover tbody tr {
    &:hover {
      td.peer-data,
      td.help-text {
        background-color: #ececec;
      }
    }
  }

  .service-select {
    max-width: 40%;
  }
}
</style>

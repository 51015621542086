<template>
  <b-row class="multi-metrics">
    <b-col
      cols="1"
      :class="[
        hasPrevious ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div v-if="selectMultiSelectObject">
        <i class="fa fa-backward" @click="movePreviousMetric"></i>
      </div>
      <div v-if="selectMultiSelectObject">Prev Report</div>
    </b-col>
    <b-col cols="10" class="p-0 metric-table">
      <div class="mh-20 pr-0 pl-0">
        <technographic-report
          :selectedReport="selectMultiSelectObject"
          :reports="metricsGroup"
          @updateMetrics="updateMetrics"
          @moveToSingleService="moveToSingleService"
          v-if="isTechnographicReport"
        ></technographic-report>
        <table class="table table-hover" v-else>
          <thead>
            <tr>
              <th width="30%" class="pr-0 pl-0">
                <multiselect
                  track-by="id"
                  label="name"
                  :allowEmpty="false"
                  :showLabels="false"
                  :value="selectMultiSelectObject"
                  :options="metricsGroup"
                  :searchable="false"
                  class="title-select pr-0 pl-0"
                  @select="updateMetrics($event)"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span>
                      <span class="pr-1">{{ props.option.name }} </span>
                      <span
                        class="p-0 pr-1 pb-1  favorite__star__selected"
                        v-if="props.option.category === 'key_metrics'"
                        ><b> &#x2605;</b></span
                      >
                      <span :class="props.option.showShared">
                        <b-badge pill variant="secondary" class="px-1"
                          >Shared
                        </b-badge>
                      </span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <span>
                      <span class="pr-1">{{ props.option.name }} </span>
                      <span
                        class="p-0 pr-1 pb-1  favorite__star__selected"
                        v-if="props.option.category === 'key_metrics'"
                        ><b> &#x2605;</b></span
                      >
                      <span :class="props.option.showShared">
                        <b-badge pill variant="secondary" class="px-1"
                          >Shared
                        </b-badge>
                      </span>
                    </span>
                  </template>
                </multiselect>
              </th>
              <th
                class="text-right text-nowrap"
                v-for="index in 5"
                :key="index"
              >
                <div class="d-inline-block" @click="showPeriodView(index)">
                  <div class="text-center">
                    {{ headers['value' + index][0] }}
                  </div>
                  {{ headers['value' + index][1] }}
                </div>
              </th>
              <th v-if="showPeers" class="text-center" width="2%"></th>
              <th v-if="showPeers" class="text-right peer-data text-nowrap">
                <div class="d-inline-block">
                  <div class="text-center">{{ this.headers.peer[0] }}</div>
                  {{ this.headers.peer[1] }}
                </div>
              </th>
              <th
                v-if="showPeers"
                class="text-right pr-1 peer-data text-nowrap"
                width="10%"
              >
                <div class="d-inline-block">
                  <div class="text-center">
                    {{ this.headers.national_rank[0] }}
                  </div>
                  {{ this.headers.national_rank[1] }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="multi_metrics.metricsLoading">
            <tr>
              <td :colspan="showPeers ? 9 : 6">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="20"
                  ></content-placeholders-text>
                </content-placeholders>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="(data, index) in tableData"
              :key="index"
              :class="data.type"
            >
              <td
                v-if="data.type === 'graph'"
                :colspan="showPeers ? 9 : 6"
                class="pb-0"
              >
                <div class="row px-3">
                  <div
                    class="col-sm-4 px-1 pb-2 cursor-pointer"
                    v-for="(metric, index) in data.metrics"
                    :key="index"
                    @click="moveToSingleMetricView(metric)"
                  >
                    <InlineChart
                      :metric="metric"
                      :reportMetric="getMatchingReportMetric(metric)"
                      :headers="headers"
                    ></InlineChart>
                  </div>
                </div>
              </td>
              <td
                :class="[
                  data.type &&
                  ['space', 'header', 'lineBreak'].includes(data.type)
                    ? ''
                    : 'metric-name',
                  'text-left pr-0 pl-0'
                ]"
                v-else
              >
                <b-row>
                  <b-col cols="9">
                    <div
                      :style="{ paddingLeft: data.indent + 'em' }"
                      :class="{ label: data.graph_plots === undefined }"
                      @click="showSingleMetricView(data)"
                      v-b-tooltip
                      :title="data.name"
                    >
                      {{ data.name }}
                    </div>
                  </b-col>

                  <b-col cols="3" class="px-0">
                    <SparkGraph
                      :plotData="data.graph_plots"
                      v-if="
                        data.graph_plots && showSparkGraph && !data.hideGraph
                      "
                    ></SparkGraph>
                  </b-col>
                </b-row>
              </td>
              <td
                class="text-right"
                v-if="data.format && data.type !== 'graph'"
              >
                {{ data.value1 }}
              </td>
              <td
                class="text-right"
                v-if="data.format && data.type !== 'graph'"
              >
                {{ data.value2 }}
              </td>
              <td
                class="text-right"
                v-if="data.format && data.type !== 'graph'"
              >
                {{ data.value3 }}
              </td>
              <td
                class="text-right"
                v-if="data.format && data.type !== 'graph'"
              >
                {{ data.value4 }}
              </td>
              <td
                class="text-right"
                v-if="data.format && data.type !== 'graph'"
              >
                {{ data.value5 }}
              </td>
              <td
                class="text-right"
                v-if="!data.format && data.type !== 'graph'"
                colspan="5"
              >
                {{ data.value5 }}
              </td>
              <td
                class="text-right"
                v-if="showPeers && data.type !== 'graph'"
              ></td>
              <td
                class="text-right peer-data"
                v-if="showPeers && data.type !== 'graph'"
              >
                {{ data.peer }}
              </td>
              <td
                class="text-right peer-data pr-2"
                v-if="showPeers && data.type !== 'graph'"
              >
                {{ data.national_rank }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
    <b-col
      cols="1"
      :class="[
        hasNext ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div v-if="selectMultiSelectObject">
        <i class="fa fa-forward" @click="moveNextMetric"></i>
      </div>
      <div v-if="selectMultiSelectObject">Next Report</div>
    </b-col>
  </b-row>
</template>

<script>
// global
import { mapGetters } from 'vuex'
// ui components
import SparkGraph from '../components/SparkGraph'
import Multiselect from 'vue-multiselect'
import TechnographicReport from '../components/TechnographicReport'
import InlineChart from '../components/InlineChart'
// utils
import MetricFormatter from '../helpers/metric.formatter'

export default {
  name: 'MultiMetric',
  components: {
    SparkGraph,
    Multiselect,
    InlineChart,
    TechnographicReport
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'showPeers',
      'metric_args',
      'showSparkGraph',
      'multi_metrics',
      'single_metrics',
      'isTechnographicReport'
    ]),
    headers: function() {
      return this.multi_metrics.headers
    },
    metricsGroup: function() {
      return this.multi_metrics.metricsList
    },
    hasPrevious: function() {
      return this.reportIndex !== 0
    },
    hasNext: function() {
      return this.reportIndex !== this.metricsGroup.length - 1
    },
    selectMultiSelectObject: function() {
      return this.metricsGroup[this.reportIndex]
    },
    reportIndex: function() {
      return this.metricsGroup.findIndex(
        group => group.id === this.multi_metrics.metricGroupId
      )
    },
    tableData() {
      let metricFormatter = new MetricFormatter()
      return metricFormatter.formattedReport
    }
  },
  methods: {
    showPeriodView(index) {
      if (this.headers['value' + index].length === 2) return
      this.$emit('moveToPeriodMetric', this.headers['value' + index][2])
    },
    showSingleMetricView(metricData) {
      if (metricData.graph_plots === undefined) return
      this.$emit('moveToSingleMetric', metricData)
    },
    moveToSingleMetricView(metric) {
      let metricData = this.getMatchingReportMetric(metric)
      this.showSingleMetricView(metricData)
    },
    moveToSingleService(service) {
      this.$emit('moveToSingleMetric', service)
    },
    updateMetrics: function(selected) {
      this.$emit('updateMetrics', selected)
    },
    getMatchingReportMetric(metric) {
      return this.tableData.find(td => td.metric_id === metric.id)
    },
    movePreviousMetric: function() {
      if (this.hasPrevious) {
        let group = this.metricsGroup[this.reportIndex - 1]
        this.$emit('updateMetrics', group)
      }
    },
    moveNextMetric: function() {
      if (this.hasNext) {
        let group = this.metricsGroup[this.reportIndex + 1]
        this.$emit('updateMetrics', group)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-metrics {
  .table {
    .service-name {
      font-weight: 600;
    }

    .no-pointer {
      cursor: initial !important;
    }

    tr {
      &.lineBreak {
        border-top: solid 1px #a4b7c1;

        td {
          padding: 0rem;
        }
      }
    }

    th {
      padding: 0.5rem;
      cursor: pointer;
    }

    tr {
      &.graph {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
      }
    }

    td {
      padding: 0.5rem;
      &.metric-name {
        padding-left: 0.75rem;
        cursor: pointer;

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }
  }

  .label {
    cursor: default;
    font-weight: bold;
  }

  .table-holder {
    height: 30rem;
  }

  .metric-navigator {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 15rem;

    i {
      cursor: pointer;
    }
    div {
      text-align: center;
    }

    &.disabled-navigate {
      opacity: 0.5;
      i {
        cursor: not-allowed;
      }
    }
  }

  .loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .peer-data {
    background-color: #f0f0f0;
  }

  .table-hover tbody tr {
    &:hover {
      td.peer-data {
        background-color: #ececec;
      }
    }
  }

  .navigator {
    i {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 992px) {
    table {
      table-layout: fixed;
    }

    .col-1.navigator {
      max-width: 6.5% !important;
      flex: 0 0 6.5% !important;
    }

    .col-10.metric-table {
      max-width: 87%;
      flex: 0 0 87%;
    }
  }

  @media (max-width: 991.98px) {
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  .favorite__star__selected {
    color: #a7ca77;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
  }
}
</style>

<style lang="scss">
@media print {
  .multi-metrics {
    margin-right: 0;
    margin-left: 0;

    table {
      display: table !important;
    }

    .metric-navigator {
      display: none !important;
    }

    .col-10.metric-table {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}
</style>

<template>
  <div>
    <component is="style" type="text/css" media="print">
      @media print {body {zoom: 100%;}}
    </component>
  </div>
</template>
<script>
export default {
  name: 'NormalZoomMode'
}
</script>

<template>
  <div>
    <v-wait format="fetchingData">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <b-row v-if="period_metrics.metricsLoading">
        <template>
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      </b-row>
      <Period v-else
        :tableData="tableData"
        @updateMetrics="loadMetrics"
        @metricChanged="periodChange"
      ></Period>
    </v-wait>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import customApi from '../api/metrics.custom'
import Period from './Period'

export default {
  name: 'CustomPeriodMetric',
  components: {
    Period
  },
  created() {
    let vm = this
    vm.$wait.start('fetchingData')
    this.fetchMetricsList().then(
        function() {
          customApi.getPeriodMetricData(vm).then(() => {
              vm.$wait.end('fetchingData')
          })
        }.bind(this))
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['metric_args', 'multi_metrics', 'single_metrics','period_metrics']),
    tableData: function() {
      return !this.period_metrics.metrics ? [] :this.period_metrics.metrics
    }
  },
  methods: {
    fetchMetricsList() {
      return  !this.period_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    fetchSingleMetricsList() {
      return !this.single_metrics.metricsList.length
        ? customApi.getCustomReportList(this)
        : Promise.resolve()
    },
    periodChange: function(period) {
      this.$store.commit('period_metrics', {
        selectedPeriod: period.id,
        metricsLoading: true
      })
      customApi.getPeriodMetricData(this)
    },
    loadMetrics(group) {
      this.$store.commit('period_metrics', {
        metricGroupId: group.id,
        metricsLoading: true
      })
      this.$store.commit('update_metric_args', { reportTypeId: group.id })
      customApi.getPeriodMetricData(this)
      this.updateOtherMetricAttributes(group.id)
    },
    updateOtherMetricAttributes(metricGroupId) {
      this.$store.commit('multi_metrics', { metricGroupId: metricGroupId })
        this.$store.commit('single_metrics', {
          metricGroupId: metricGroupId,
          selectedMetric: undefined
        })
      }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <b-row class="single-metrics">
    <b-col
      cols="1"
      :class="[
        hasPrevious ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div>
        <i class="fa fa-backward" @click="movePreviousMetric"></i>
      </div>
      <div>Prev Item</div>
    </b-col>
    <b-col cols="10" class="p-0 metric-data">
      <div class="report-select-options pb-3">
        <div>
          <multiselect
            track-by="group_id"
            label="group_name"
            :allowEmpty="false"
            :showLabels="false"
            :value="selectMetricGroup"
            :options="metricsGroup"
            :searchable="false"
            class="title-select min-width-20"
            @select="metricGroupChange($event)"
          >
            <template slot="singleLabel" slot-scope="props">
              <span>
                <span class="pr-1">{{ props.option.group_name }} </span>
                <span
                  class="p-0 pb-1 pr-1 favorite__star__selected"
                  v-if="props.option.category === 'key_metrics'"
                  ><b> &#x2605;</b></span
                >
                <span :class="props.option.showShared">
                  <b-badge pill variant="secondary" class="px-1">
                    Shared
                  </b-badge>
                </span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <span>
                <span class="pr-1">{{ props.option.group_name }} </span>
                <span
                  class="p-0 pb-1 pr-1 favorite__star__selected"
                  v-if="props.option.category === 'key_metrics'"
                  ><b> &#x2605;</b></span
                >
                <span :class="props.option.showShared">
                  <b-badge pill variant="secondary" class="px-1">
                    Shared
                  </b-badge>
                </span>
              </span>
            </template>
          </multiselect>
        </div>
        <div>
          <multiselect
            track-by="metric_id"
            label="metric_name"
            :allowEmpty="false"
            :showLabels="false"
            :value="selectMetric"
            :options="individualMetrics"
            :searchable="false"
            class="title-select min-width-20"
            @select="metricChanged($event)"
          ></multiselect>
        </div>
      </div>
      <b-row v-if="single_metrics.metricsLoading">
        <b-col cols="12">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <div v-if="isTechnographicReport">
            <technographics-single-service
              :tableData="getTechnographicData(single_metrics.peerTableData)"
              :metricsLoading="single_metrics.metricsLoading"
            ></technographics-single-service>
          </div>
          <div v-else>
            <div v-if="isBarChart()">
              <BarChart
                :chartData="chartData"
                :format="single_metrics.dataFormat"
                :metricGroup="selectMetricGroup"
                :metric="selectMetric"
              ></BarChart>
            </div>
            <div v-else-if="isLineChart()">
              <LineChart
                :chartData="chartData"
                :format="single_metrics.dataFormat"
                :metricGroup="selectMetricGroup"
                :metric="selectMetric"
              ></LineChart>
            </div>
            <div v-else>
              <PeerTable
                :headers="single_metrics.headers"
                :tableData="single_metrics.peerTableData"
                :metricsLoading="single_metrics.metricsLoading"
                :metric="selectMetric"
              ></PeerTable>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="1"
      :class="[
        hasNext ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div v-if="selectMetricGroup">
        <i class="fa fa-forward" @click="moveNextMetric"></i>
      </div>
      <div v-if="selectMetricGroup">Next Item</div>
    </b-col>
  </b-row>
</template>

<script>
// global
import _ from 'lodash'
import { mapGetters } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
import TechnographicsSingleService from '../components/TechnographicsSingleService'
import BarChart from '../components/BarChart'
import LineChart from '../components/LineChart'
import PeerTable from './PeerTable'

export default {
  name: 'SingleMetric',
  components: {
    BarChart,
    LineChart,
    PeerTable,
    Multiselect,
    TechnographicsSingleService
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'single_metrics',
      'chartType',
      'showPeers',
      'selected_span',
      'selected_peer_group',
      'isTechnographicReport'
    ]),
    metricsGroup: function() {
      return this.single_metrics.metricsList
    },
    individualMetrics: function() {
      return this.single_metrics.individualMetricsList
    },
    metricsData: function() {
      return this.single_metrics.metrics.graph_plots
    },
    peerMetricsData: function() {
      return this.showPeers
        ? (this.single_metrics.peer_metrics &&
            this.single_metrics.peer_metrics.graph_plots) ||
            []
        : []
    },
    chartLabels: function() {
      return _.map(this.single_metrics.headers, function(header) {
        return header[1]
      })
    },
    metricsPlotData: function() {
      return {
        backgroundColor: this.isBarChart() ? '#A5C488' : 'transparent',
        borderColor: '#7EAA55',
        data: this.metricsData.map(md => (md === 'NA' ? '0' : md))
      }
    },
    peerMetricsPlotData: function() {
      return {
        backgroundColor: this.isBarChart() ? '#A6A6A6' : 'transparent',
        borderColor: '#A6A6A6',
        data: this.peerMetricsData.map(pmd => (pmd === 'NA' ? '0' : pmd))
      }
    },
    chartDataSets: function() {
      if (this.showPeers) {
        return [this.metricsPlotData, this.peerMetricsPlotData]
      } else {
        return [this.metricsPlotData]
      }
    },
    chartData: function() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDataSets
      }
    },
    selectMetricGroup: function() {
      return this.metricsGroup[this.reportIndex]
    },
    selectMetric: function() {
      return this.individualMetrics[this.metricIndex]
    },
    reportIndex: function() {
      return this.metricsGroup.findIndex(
        group => group.group_id === this.single_metrics.metricGroupId
      )
    },
    metricIndex: function() {
      return this.individualMetrics.findIndex(
        metric =>
          metric.metric_id.toString() ===
          this.single_metrics.selectedMetric.toString()
      )
    },
    hasPrevious: function() {
      return this.metricIndex !== 0
    },
    hasNext: function() {
      return this.metricIndex !== this.individualMetrics.length - 1
    }
  },
  methods: {
    metricGroupChange: function(selected) {
      this.$emit(
        'metricGroupChanged',
        selected.group_id,
        selected.technographicReport
      )
    },
    metricChanged: function(selected) {
      this.$emit('metricChanged', selected)
    },
    isBarChart: function() {
      return this.chartType === 'bar'
    },
    isLineChart: function() {
      return this.chartType === 'line'
    },
    movePreviousMetric: function() {
      if (this.hasPrevious) {
        let metric = this.individualMetrics[this.metricIndex - 1]
        this.metricChanged(metric)
      }
    },
    moveNextMetric: function() {
      if (this.hasNext) {
        let metric = this.individualMetrics[this.metricIndex + 1]
        this.metricChanged(metric)
      }
    },
    getTechnographicData(peers) {
      return peers.map(peer => {
        return {
          bank_name: peer.bank_name,
          bank_state: peer.bank_state,
          bank_city: peer.bank_city,
          has_service: peer.value5.has_service,
          vendor: peer.value5.vendor,
          product: peer.value5.product
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>
<style lang="scss">
.single-metrics {
  .metric-navigator {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 15rem;

    i {
      cursor: pointer;
    }
    div {
      text-align: center;
    }

    &.disabled-navigate {
      opacity: 0.5;
      i {
        cursor: not-allowed;
      }
    }
  }

  .loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .report-select-options {
    display: flex;
    justify-content: space-between;
  }

  .navigator {
    i {
      font-size: 1.3rem;
    }
  }

  .col-1.navigator {
    max-width: 6.5% !important;
    flex: 0 0 6.5% !important;
  }

  .col-10.metric-data {
    max-width: 87% !important;
    flex: 0 0 87% !important;
  }

  .line-legend {
    display: inline-block;
    width: 4rem;
    vertical-align: middle;

    &.peer-group {
      border: 2px solid #a6a6a6;
    }
    &.fi {
      border: 2px solid #abc28e;
    }
  }

  .bar-legend {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    vertical-align: middle;

    &.peer-group {
      background: #a6a6a6;
    }
    &.fi {
      background: #abc28e;
    }
  }
}
</style>

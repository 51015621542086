<template>
  <div class="row">
    <div class="col-sm-12">
      <b-card
        class="page-card fin-reporter"
        header-class="page-card-with-logo page-card-header"
        :body-class="cardBodyClasses"
      >
        <div slot="header">
          <b-row class="fit-to-center">
            <b-col cols="6">
              <div class="page-header-left">
                <div class="pr-1 header-icon"><i :class="cardIcon"></i></div>
                <div>
                  <h5 class="mb-0">
                    <b>{{ pageName }}</b>
                    <span v-if="pageType">
                      - <i>{{ pageType }}</i></span
                    >
                  </h5>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="page-header-right">
                <header-logo
                  logoType="fi"
                  :logoUrl="logoUrl"
                  :logoId="institutionId"
                ></header-logo>
                <div class="pr-2">
                  <h5 class="mb-0">{{ institutionName }}</h5>
                </div>
                <div
                  class="page-settings multi-col mt-2 d-flex align-items-center"
                  v-if="showAside"
                >
                  <b-nav-item-dropdown
                    right
                    no-caret
                    class="print-pdf-dd d-flex"
                    :disabled="isSingleMetricView && isScoreMetric"
                  >
                    <template slot="button-content">
                      <i
                        title="Download Report"
                        class="cuis-cloud-download font-xl pl-1 pr-1 cursor-pointer"
                        @click="downloadReport"
                      ></i>
                    </template>
                  </b-nav-item-dropdown>
                  <div class="text-right pr-2">
                    <b-nav-item-dropdown
                      right
                      no-caret
                      class="print-pdf-dd d-flex"
                      :disabled="
                        isPeriodView ||
                          (isSingleMetricView &&
                            (isPeerTableView || isTechnographicReport))
                      "
                    >
                      <template slot="button-content">
                        <v-wait for="preparingPrint">
                          <template slot="waiting">
                            <i class="fa fa-lg fa-spinner fa-spin"></i>
                          </template>
                          <i
                            title="Print Report"
                            class="cuis-print d-block card-icon-font cursor-pointer"
                            v-if="isMultiMetricView"
                          ></i>
                          <i
                            title="Print Report"
                            class="cuis-print d-block card-icon-font cursor-pointer"
                            @click="printReport(false)"
                            v-else
                          ></i>
                        </v-wait>
                      </template>

                      <div v-if="isMultiMetricView">
                        <b-dropdown-item @click.prevent="printReport(false)">
                          Print Report
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click.prevent="printReport(true)"
                          v-if="!isTechnographicReport"
                        >
                          Print Report with Metric Charts
                        </b-dropdown-item>
                      </div>
                    </b-nav-item-dropdown>
                  </div>
                  <div
                    class="fa fa-cog fa-lg"
                    :disabled="isAsideOpen"
                    @click="showSettings"
                  ></div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <slot name="page_content"></slot>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import customAPI from '@/modules/reporter/api/metrics.custom'

export default {
  name: 'LayoutPageCardWithSettings',
  components: {
    HeaderLogo
  },
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    pageType: {
      type: String,
      required: false
    },
    institutionId: {
      type: Number,
      required: true
    },
    institutionName: {
      type: String,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    },
    cardBodyClasses: {
      type: Array,
      required: false
    },
    iconClass: {
      type: Array,
      required: false
    }
  },
  beforeMount() {
    if (this.isAsideOpen) {
      document.body.classList.remove('aside-menu-hidden')
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['allSingleMetricsData']),
    ...mapGetters([
      'showAside',
      'isAsideOpen',
      'isMultiMetricView',
      'isSingleMetricView',
      'isPeriodView',
      'isCustomReport',
      'isScoreMetric',
      'isPeerTableView',
      'isTechnographicReport'
    ]),
    cardIcon() {
      return this.iconClass ? this.iconClass : ['fa', 'fa-bar-chart', 'fa-lg']
    }
  },
  methods: {
    ...mapActions(['getAllSingleMetricsData']),
    showSettings(e) {
      e.preventDefault()
      document.body.classList.remove('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', true)
    },
    closeSettings(e) {
      e.preventDefault()
      document.body.classList.add('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', false)
    },
    downloadReport() {
      if (this.isMultiMetricView) {
        this.isTechnographicReport
          ? customAPI.downloadMultiServiceReport(this)
          : customAPI.downloadMultiMetricReport(this)
      } else if (this.isPeriodView) {
        customAPI.downloadPeriodReport(this)
      } else {
        this.isTechnographicReport
          ? customAPI.downloadSingleServiceReport(this)
          : customAPI.downloadSingleMetricReport(this)
      }
    },
    printReport(withSingleMetrics) {
      this.$store.commit('setPrintWithSingleMetricsData', withSingleMetrics)
      this.$toasted.show('Preparing data, please wait ...', {
        icon: 'print',
        type: 'success'
      })
      if (withSingleMetrics) {
        this.$wait.start('preparingPrint')
        this.getAllSingleMetricsData().then(() => {
          setTimeout(
            function() {
              this.$nextTick(() => {
                this.$toasted.clear()
                window.print()
                this.$wait.end('preparingPrint')
              })
            }.bind(this),
            0
          )
        })
      } else {
        this.$nextTick(() => {
          this.$toasted.clear()
          window.print()
        })
      }
    }
  }
}
</script>

<style lang="scss">
@media print {
  // @page rotated {
  //   size: landscape;
  // }

  // .fin-reporter {
  //   page: rotated;
  // }

  .multi-col {
    display: none !important;
  }
}

.print-pdf-dd {
  a.nav-link {
    padding: 0 0.25rem;
    color: #151b1e;
  }
  a.nav-link.disabled {
    color: #536c79;
  }
}
</style>

<template>
  <div class="row justify-content-center decision-tree">
    <div class="col-sm-10">
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-1"
        header-class="px-2 py-2"
      >
        <div class="text-center"><b>Generating Report. Please wait ...</b></div>
        <b-progress
          :value="progress || 0"
          :max="100"
          show-progress
          animated
          variant="success"
          class="mx-3 mt-3"
        >
          <b-progress-bar :value="progress"
            ><b>{{ progress }}%</b></b-progress-bar
          >
        </b-progress>
      </b-card>
      <b-card
        class="std-border mb-3"
        body-class="px-3 pb-3 pt-1"
        header-class="px-2 py-2"
      >
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="14"></content-placeholders-text>
        </content-placeholders>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportGeneratorPlaceholder',
  props: {
    progress: {
      required: true,
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-row class="peer-table">
      <b-col cols="12">
        <b-row>
          <b-col cols="4">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
          responsive="sm"
          striped
          hover
          :busy="loading"
          :items="tableData"
          :sort-compare="sortPeerData"
          :sort-by.sync="sortBy"
          :fields="fieldsList"
          :filter="filter"
          @filtered="afterFilter"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template v-slot:head(bank_name)="data">
            {{ data.label }}
          </template>
          <template v-slot:head(bank_city)="data">
            {{ data.label }}
          </template>
          <template v-slot:head(bank_state)="data">
            {{ data.label }}
          </template>
          <template v-slot:head()="data">
            <div class="text-right text-nowrap">
              <div class="d-inline-block">
                <div class="text-center">{{ data.label.split('::')[0] }}</div>
                {{ data.label.split('::')[1] }}
              </div>
            </div>
          </template>
          <template v-slot:cell(bank_name)="data">
            <div class="text-ellipsis" v-b-tooltip :title="data.item.bank_name">
              {{ data.item.bank_name }}
            </div>
          </template>
          <template v-slot:cell(bank_city)="data">
            <div class="text-ellipsis" v-b-tooltip :title="data.item.bank_city">
              {{ data.item.bank_city }}
            </div>
          </template>
          <template v-slot:cell(bank_state)="data">
            <div
              class="text-ellipsis"
              v-b-tooltip
              :title="data.item.bank_state"
            >
              {{ data.item.bank_state }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div class="text-right">{{ data.value || 'NA' }}</div>
          </template>
        </b-table>

        <b-row>
          <b-col md="6" class="my-1">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PeerTable',
  props: ['headers', 'tableData', 'loading', 'metric'],
  data() {
    return {
      sortBy: 'bank_name',
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: this.tableData.length
    }
  },
  computed: {
    fieldsList() {
      let values = [
        {
          key: 'bank_name',
          label: 'Financial Institution',
          sortable: true,
          thClass: 'bank-name-col pb-4'
        },
        {
          key: 'bank_city',
          label: 'City',
          sortable: false,
          thClass: 'bank-city-col pb-4'
        },
        {
          key: 'bank_state',
          label: 'State',
          sortable: false,
          thClass: 'bank-state-col pb-4'
        }
      ]

      if (this.isNonMetric()) {
        values = values.concat([
          {
            key: 'value5',
            label: this.headers['value5'].join('::'),
            sortable: true
          }
        ])
      } else {
        values = values.concat([
          {
            key: 'value1',
            label: this.headers['value1'].join('::'),
            sortable: true
          },
          {
            key: 'value2',
            label: this.headers['value2'].join('::'),
            sortable: true
          },
          {
            key: 'value3',
            label: this.headers['value3'].join('::'),
            sortable: true
          },
          {
            key: 'value4',
            label: this.headers['value4'].join('::'),
            sortable: true
          },
          {
            key: 'value5',
            label: this.headers['value5'].join('::'),
            sortable: true
          }
        ])
      }
      return values
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    isNonMetric: function() {
      return this.metric['metric_id'].toString().startsWith('non_metric_id:')
    },
    sortPeerData(record1, record2, field) {
      let columnCompare
      if (field === 'bank_name') {
        let field1 = record1[field] || ''
        let field2 = record2[field] || ''
        columnCompare = field1
          .toString()
          .localeCompare(field2.toString(), undefined, { numeric: true })
      } else {
        let field1 = parseFloat(
          (record1[field] || '0').replace(/[^0-9.-]+/g, '')
        )
        let field2 = parseFloat(
          (record2[field] || '0').replace(/[^0-9.-]+/g, '')
        )

        columnCompare = field1 === field2 ? 0 : field1 < field2 ? -1 : 1
      }
      if (columnCompare === 0) {
        return record1.bank_name < record2.bank_name
          ? -1
          : record1.bank_name > record2.bank_name
          ? 1
          : 0
      } else {
        return columnCompare
      }
    }
  }
}
</script>

<style lang="scss">
.peer-table {
  .metric-navigator {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    i {
      cursor: pointer;
    }
    div {
      text-align: center;
    }

    &.disabled-navigate {
      opacity: 0.5;
      i {
        cursor: not-allowed;
      }
    }
  }

  table {
    @media (min-width: 992px) {
      table-layout: fixed;
    }

    @media (max-width: 991.98px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    th:focus {
      outline: none;
    }

    td {
      padding: 0.5rem;
    }
  }

  .bank-name-col {
    width: 30%;
  }

  .bank-city-col {
    width: 12%;
    padding-left: 0.5rem;
  }

  .bank-state-col {
    width: 8%;
    padding-left: 0.5rem;
  }
}
</style>

<template>
  <b-row class="multi-metrics">
    <b-col
      cols="1"
      :class="[
        hasPrevious ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div v-if="selectMultiSelectObject">
        <i class="fa fa-backward" @click="movePreviousMetric"></i>
      </div>
      <div v-if="selectMultiSelectObject">Prev Report</div>
    </b-col>
    <b-col cols="10" class="p-0 metric-table">
      <b-row class="pb-2">
        <b-col cols="5">
          <multiselect
            track-by="id"
            label="name"
            :allowEmpty="false"
            :showLabels="false"
            :value="selectMultiSelectObject"
            :options="metricsGroup"
            :searchable="false"
            class="title-select pr-0 pl-0"
            @select="updateMetrics($event)"
          >
            <template slot="singleLabel" slot-scope="props">
              <span>
                <span class="pr-1">{{ props.option.name }} </span>
                <span
                  class="p-0 pb-1 pr-1 favorite__star__selected"
                  v-if="props.option.category === 'key_metrics'"
                  ><b> &#x2605;</b></span
                >
                <span :class="props.option.showShared">
                  <b-badge pill variant="secondary" class="px-1">
                    Shared
                  </b-badge>
                </span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <span>
                <span class="pr-1">{{ props.option.name }} </span>
                <span
                  class="p-0 pb-1 pr-1 favorite__star__selected"
                  v-if="props.option.category === 'key_metrics'"
                  ><b> &#x2605;</b></span
                >
                <span :class="props.option.showShared">
                  <b-badge pill variant="secondary" class="px-1">
                    Shared
                  </b-badge>
                </span>
              </span>
            </template>
          </multiselect>
        </b-col>
        <b-col cols="5"> </b-col>
        <b-col cols="2">
          <multiselect
            track-by="id"
            label="name"
            :allowEmpty="false"
            :showLabels="false"
            :value="selectMetric"
            :options="individualPeriods"
            :searchable="false"
            class="title-select min-width-9"
            @select="metricChanged($event)"
          ></multiselect>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <b-row class="peer-table">
        <b-col cols="12">
          <b-row>
            <b-col cols="4">
              <b-input-group>
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fa fa-search"></i
                  ></span>
                </div>
                <b-form-input
                  type="text"
                  v-model="filter"
                  @keydown.native.stop
                  placeholder="Search"
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <b-table
            responsive
            striped
            hover
            :busy="period_metrics.metricsLoading"
            :fields="fieldsList"
            :items="tableData"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="afterFilter"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'bank_name'
                      ? '15rem'
                      : field.key === 'bank_state'
                      ? '4rem'
                      : '10rem'
                }"
              />
            </template>
            <template v-slot:cell(bank_name)="data">
              <div
                class="text-ellipsis"
                v-b-tooltip
                :title="data.item.bank_name"
              >
                {{ data.item.bank_name }}
              </div>
            </template>
            <template v-slot:cell(bank_state)="data">
              <div
                class="text-ellipsis"
                v-b-tooltip
                :title="data.item.bank_state"
              >
                {{ data.item.bank_state }}
              </div>
            </template>
            <template v-slot:cell(bank_city)="data">
              <div
                class="text-ellipsis"
                v-b-tooltip
                :title="data.item.bank_city"
              >
                {{ data.item.bank_city }}
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col md="6" class="my-1">
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0"
              >
              </b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="1"
      :class="[
        hasNext ? '' : 'disabled-navigate',
        'metric-navigator',
        'navigator'
      ]"
    >
      <div v-if="selectMultiSelectObject">
        <i class="fa fa-forward" @click="moveNextMetric"></i>
      </div>
      <div v-if="selectMultiSelectObject">Next Report</div>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'

export default {
  name: 'MultiMetric',
  components: {
    Multiselect
  },
  props: {
    tableData: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      sortBy: 'bank_name',
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: this.tableData.length
    }
  },
  computed: {
    ...mapGetters([
      'showPeers',
      'metric_args',
      'showSparkGraph',
      'multi_metrics',
      'single_metrics',
      'period_metrics'
    ]),
    fieldsList() {
      let list = [
        {
          key: 'bank_name',
          label: 'Financial Institution',
          sortable: true,
          stickyColumn: true,
          thClass: 'align-top fixed-column',
          tdClass: 'fixed-column'
        },
        {
          key: 'bank_city',
          label: 'City',
          sortable: false,
          thClass: 'align-top'
        },
        {
          key: 'bank_state',
          label: 'State',
          sortable: false,
          thClass: 'align-top'
        }
      ]
      return list.concat(
        this.headers.map(function(header, index) {
          return {
            key: 'values[' + index + ']',
            thClass: 'align-top text-right',
            tdClass: 'text-right',
            label: header,
            sortable: true
          }
        })
      )
    },
    headers: function() {
      return this.period_metrics.headers
    },
    metricsGroup: function() {
      return this.period_metrics.metricsList
    },
    hasPrevious: function() {
      return this.reportIndex !== 0
    },
    hasNext: function() {
      return this.reportIndex !== this.metricsGroup.length - 1
    },
    selectMultiSelectObject: function() {
      return this.metricsGroup[this.reportIndex]
    },
    selectMetric: function() {
      return (
        this.individualPeriods &&
        this.individualPeriods.find(
          period => period.id === this.period_metrics.selectedPeriod
        )
      )
    },
    reportIndex: function() {
      return this.metricsGroup.findIndex(
        group => group.id === this.period_metrics.metricGroupId
      )
    },
    individualPeriods: function() {
      return this.period_metrics.periodList
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showSingleMetricView(metricData) {
      if (metricData.graph_plots === undefined) return
      this.$emit('moveToSingleMetric', metricData)
    },
    updateMetrics: function(selected) {
      this.$emit('updateMetrics', selected)
    },
    movePreviousMetric: function() {
      if (this.hasPrevious) {
        let group = this.metricsGroup[this.reportIndex - 1]
        this.$emit('updateMetrics', group)
      }
    },
    moveNextMetric: function() {
      if (this.hasNext) {
        let group = this.metricsGroup[this.reportIndex + 1]
        this.$emit('updateMetrics', group)
      }
    },
    metricChanged: function(selected) {
      this.$emit('metricChanged', selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.peer-table {
  .metric-navigator {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    i {
      cursor: pointer;
    }
    div {
      text-align: center;
    }

    &.disabled-navigate {
      opacity: 0.5;
      i {
        cursor: not-allowed;
      }
    }
  }
  table {
    @media (min-width: 992px) {
      table-layout: fixed;
    }

    @media (max-width: 991.98px) {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    td {
      padding: 0.5rem;
    }
  }

  .bank-name-col {
    width: 10rem;
  }
}

.multi-metrics {
  .report-select-options {
    display: flex;
    justify-content: space-between;
  }
  .label {
    cursor: default;
    font-weight: bold;
  }

  .table-holder {
    height: 30rem;
  }

  .metric-navigator {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 15rem;

    i {
      cursor: pointer;
    }
    div {
      text-align: center;
    }

    &.disabled-navigate {
      opacity: 0.5;
      i {
        cursor: not-allowed;
      }
    }
  }

  .loading {
    opacity: 0.5;
    pointer-events: none;
  }

  .peer-data {
    background-color: #f0f0f0;
  }

  .table-hover tbody tr {
    &:hover {
      td.peer-data {
        background-color: #ececec;
      }
    }
  }

  .navigator {
    i {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 992px) {
    table {
      table-layout: fixed;
    }

    .col-1.navigator {
      max-width: 6.5% !important;
      flex: 0 0 6.5% !important;
    }

    .col-10.metric-table {
      max-width: 87%;
      flex: 0 0 87%;
    }
  }

  @media (max-width: 991.98px) {
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }
  .favorite__star__selected {
    color: #a7ca77;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
    -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
  }
}
</style>

<style lang="scss">
@media print {
  .multi-metrics {
    margin-right: 0;
    margin-left: 0;

    table {
      display: table !important;
    }

    .metric-navigator {
      display: none !important;
    }

    .col-10.metric-table {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}
</style>
